/* eslint-disable no-console */
import _ from 'lodash';
import { colors, responsiveFontSizes, createMuiTheme } from '@material-ui/core';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from '../constants';
import typography from './typography';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const failBackThemeConfigs = [
  {
    name: THEMES.DARK,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#41424b' //dark grey
        }
      }
    },
    palette: {
      type: 'dark',
      action: {
        active: colors.blueGrey[600],
        focus: 'rgba(255, 255, 255, 0.12)',
        disabled: '#575757'
      },
      selected: {
        active: 'rgba(84, 110, 122,.454)'
      },
      background: {
        default: colors.common.black,
        dark: '#11151c', //basically black
        paper: 'black',
        paperbg: '#4b4b4e' //dark grey
      },
      primary: {
        main: '#dab978', //darker cream yellow
        secondary: '#2a2d30', //lighter black
        hover: '#41424b' // slightly more lighter black
      },
      secondary: {
        main: '#d6ba81', //cream yellow
        secondary: '#313338', //lighter black
        tabs: '#171717' //almost black
      },
      tertiary: {
        main: '#252627', //basically black
        secondary: '#f3f2f5' //white
      },
      text: {
        primary: '#fff', //white
        secondary: '#e1e6f4' //white grey
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#eaeef0' //lighter grey
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      selected: {
        active: 'rgba(84, 110, 122,.454)'
      },
      background: {
        default: colors.common.black,
        dark: '#11151c', //basically black
        paper: '#fff', //white
        paperbg: '#4b4b4e' //darker grey
      },
      primary: {
        main: '#dab978', //darker cream yellow
        secondary: '#f6f6f9', //lighter grey
        hover: '#dadada' //light grey
      },
      secondary: {
        main: '#d6ba81', //cream yellow
        secondary: 'white',
        tabs: '#f2f2f2' //almost white
      },
      tertiary: {
        main: '#252627', //black
        secondary: '#171717' //white shade
      },
      text: {
        primary: '#000', //black
        secondary: '#b9bccb' //grey
      }
    },
    shadows: softShadows
  }
];

export function createTheme(settings, themeConfigsIn) {
  if (!themeConfigsIn) {
    themeConfigsIn = failBackThemeConfigs;
  }

  let themeConfig = themeConfigsIn.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    [themeConfig] = themeConfigsIn;
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
